import { disableAreas, enableAreas } from '@/api/pmarea';

import store from '@/store/store';

import { search } from '@/api/common';

const disableArea = {
	id: 'disableArea',
	selectionType: 'multiple',
	label: 'pmarea.actions.disableArea',
	functionality: 'UPDATE_PMAREA',
	checkAvailability: function (area) {
		return area && area.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmarea.actions.disableArea');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmareaid: registries[i].pmareaid
			});
		}

		//const data = await disableAreas(body);

		// eslint-disable-next-line no-unused-vars
		let [data, res2] = [await disableAreas(body), await getAreas()];

		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableArea = {
	id: 'enableArea',
	selectionType: 'multiple',
	label: 'pmarea.actions.enableArea',
	functionality: 'UPDATE_PMAREA',
	checkAvailability: function (area) {
		return area && area.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmarea.actions.enableArea');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmareaid: registries[i].pmareaid
			});
		}

		//const data = await enableAreas(body);

		// eslint-disable-next-line no-unused-vars
		let [data, res2] = [await enableAreas(body), await getAreas()];

		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

async function getAreas() {
	var session = store.getters.getSession;
	var userProperties = store.getters.getUserProperties;

	const filterArea = {
		groups: [],
		groupOp: 'and',
		rules: [
			{ field: 'lang', op: 'eq', data: session.language },
			{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid } /*,
			{ field: 'disabled', op: 'eq', data: 0 }*/
		]
	};
	const body = {
		model: 'pmarea',
		filter: filterArea
	};

	//const { areas } = await search(body);
	const promise = search(body);
	promise.then((areas) => {
		console.log('update store active areas');
		console.log(areas.data.data);

		store.commit('setAreas', areas.data.data);
	});
}

export default {
	actions: [disableArea, enableArea]
};
